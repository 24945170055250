<template>
  <div class="registration">
    <el-card shadow="never">
      <h2>Регистрация</h2>
      <el-form class="registration-form"
               :model="registrationModel"
               :rules="rules"
               ref="form"
               @submit.native.prevent="handleRegistration">
        <el-form-item prop="userName" label="Логин:">
          <el-input v-model="registrationModel.userName" placeholder="" id="registration_username"></el-input>
        </el-form-item>
        <el-form-item prop="email" label="E-mail:">
          <el-input v-model="registrationModel.email" placeholder="email@example.com" id="registration_email"></el-input>
        </el-form-item>
        <el-form-item prop="organizationName" label="Наименование организации:">
          <el-input v-model="registrationModel.organizationName" placeholder="" id="registration_org_name"></el-input>
        </el-form-item>
        <el-form-item prop="organizationId" label="Диспетчерский центр:">
          <div>
            <el-select v-model="registrationModel.organizationId" filterable id="registration_org_id">
              <el-option v-for="item in dispatchCenters"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id" />
            </el-select>
          </div>
        </el-form-item>

        <el-form-item>
          <div class="link_action_panel">
            <el-button :loading="loading"
                       class="mt-4 registration--submit"
                       type="primary"
                       native-type="submit"
                       block>Отправить</el-button>
            <el-button class="mt-4" @click="back">Отменить</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
  import regApi from '@/api/administration/userRegistration';

  export default {
    name: 'registration',
    data() {
      return {
        dispatchCenters: [],
        registrationModel: {
          userName: '',
          email: '',
          organizationId: null,
          organizationName: ''
        },
        loading: false,
        rules: {
          userName: [
            {
              required: true,
              message: 'Необходимо указать логин',
              trigger: 'blur',
            },
            {
              min: 5, max: 30, message: 'От 5 до 30 символов', trigger: 'blur'
            }
          ],
          email: [
            {
              required: true,
              message: 'Необходимо ввести E-mail',
              trigger: 'blur',
            },
            {
              type: 'email',
              message: 'Некорректный адрес электронной почты',
              trigger: ['blur', 'change']
            }
          ],
          organizationId: [
            {
              required: true,
              message: 'Необходимо выбрать диспетчерский центр',
              trigger: 'blur',
            }
          ],
          organizationName: [
            {
              required: true,
              message: 'Необходимо ввести наименование организации',
              trigger: 'blur',
            },
            {
              max: 100, message: 'До 100 символов', trigger: 'blur'
            }
          ],
        },
      };
    },
    async mounted() {
      await this.getDispatchCenters();
    },
    methods: {
      async getDispatchCenters() {
        this.loading = true;
        const res = await regApi.getDispatchCenters();
        if (res.data) {
          this.dispatchCenters = res.data;
        }
        this.loading = false;
      },
      async back() {
        await this.$router.push('/login');
      },
      async handleRegistration() {
        const valid = await this.$refs.form.validate();
        if (!valid) {
          return;
        }

        this.loading = true;
        const response = await regApi.createUserRegistration(this.registrationModel);

        if (response && response.data) {
          if (!response.data.succeeded) {
            this.$alert(response.data.resultMessage, 'Регистрация', {
              confirmButtonText: 'OK',
              type: 'warning',
              customClass: 'alert-30',
            });
          } else {
            this.$alert(response.data.resultMessage, 'Регистрация', {
              confirmButtonText: 'OK',
              type: 'success',
              customClass: 'alert-30',
              showClose: false,
              callback: this.registrationCallback
            });
          }
        }

        this.loading = false;
      },
      registrationCallback() {
        this.$router.push('/login');
      }
    },
  };
</script>

<style scoped>
  h2 {
    margin-top: 0px;
  }

  .registration {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .registration-form {
    width: 688px;
  }

  .link_action_panel {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

    .link_action_panel > button {
      padding-right: 2rem;
      padding-left: 2rem;
    }

  .el-select {
    width: 60%;
  }

  .registration-form::v-deep .el-select .el-input {
    width: 100%;
  }
</style>
